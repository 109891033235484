import React from "react";

function Fun() {
  return (
    <div id="#fun" className="Intro">

    </div>
  );
}

export default Fun;
