import React from "react";

function Projects() {
  return (
    <div id="projects" className="Intro">

    </div>
  );
}

export default Projects;
